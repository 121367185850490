// Disables the submit button while form is submitting to avoid users
// submitting the form multiple times.

document.addEventListener("submit", event => {
  const disableWith = event.submitter?.getAttribute("data-disable-with")
  if (event.target && event.submitter && disableWith) {
    event.submitter.setAttribute("disabled", "true")
    if (disableWith !== "true") {
      event.submitter.setAttribute("value", disableWith)
    }
  }
})
